<!--
 * @Author: your name
 * @Date: 2021-12-03 08:07:06
 * @LastEditTime: 2021-12-28 18:37:01
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\fx101\components\number.vue
-->
<template>
  <div class="num-comp">
    <span class="num-span din"> {{ number }}% </span>
    <span class="label-span">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    number: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.num-comp {
  display: flex;
  flex-direction: column;
  .num-span {
    font-size: 60px;
    font-weight: bold;
    color: $color-active;
    line-height: 48px;
  }
  .label-span {
    font-size: 22px;
    font-weight: 400;
    color: rgba($color: #fff, $alpha: 0.6);
    line-height: 48px;
    margin-top: 10px;
  }
}

@media screen and (max-width: $mobile-width) {
  .num-comp {
    .num-span {
      font-size: 0.55rem;
      line-height: 0.44rem;
    }
    .label-span {
      font-size: 0.22rem;
      line-height: 0.44rem;
      margin-top: 6px;
    }
  }
}
</style>
