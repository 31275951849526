<!--
 * @Author: your name
 * @Date: 2021-12-03 08:00:49
 * @LastEditTime: 2021-12-28 18:38:50
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\fx101\page2.vue
-->
<template>
  <div class="fx101-page2">
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/stld/2.png"
        alt="上太科技"
      />
    </div>
    <div class="text-div">
      <span>照明与捕虫，一机两用<br />照亮美丽环保新农村</span>
    </div>
    <div class="filter-div"></div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page2 {
  position: absolute;
  top: 0;
  height: 100vh;
  opacity: 0;
  width: 100%;
  overflow: hidden;
  .img-div {
    position: absolute;
    margin-top: 8%;
    height: 80%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 100%;
    }
  }
  .filter-div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 1)
    );
  }
  .text-div {
    opacity: 0;
    position: absolute;
    text-align: center;
    z-index: 2;
    width: 100%;
    top: 40%;
    left: 0;
    // left: 50%;
    // transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    font-size: 100px;
    font-weight: bold;
    color: $color-active;
    line-height: 120px;
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page2 {
    position: relative;
    height: auto;
    opacity: 1;
    .img-div {
      position: relative;
      margin-top: 0;
      height: unset;
      top: 0;
      left: 0;
      transform: translateX(-0);
      display: flex;
      justify-content: center;
      img {
        height: unset;
        width: 40%;
      }
    }
    .filter-div {
      opacity: 1;
    }
    .text-div {
      opacity: 1;
      font-size: 0.55rem;
      line-height: 0.73rem;
    }
  }
}
</style>
