<!--
 * @Author: your name
 * @Date: 2021-12-03 08:48:05
 * @LastEditTime: 2022-01-26 18:12:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page7.vue
-->
<template>
  <div class="fx101-page7 flexbox">
    <div class="text-div">
      <ctitle titleb="新升级照明与捕虫"></ctitle>

      <p class="desc-p">
        整机可兼顾照明与捕虫功能，各自独立控制，工作时长<br />可按需调控。
      </p>
    </div>

    <div class="img-div">
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/stld/11.jpg"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {
      isWidth: false,
      oX: 0,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page7 {
  position: relative;
  height: 100vh;
  position: relative;
  text-align: center;
  justify-content: center;

  .text-div {
    position: relative;
    z-index: 2;
    text-align: left;
    padding-right: 100px;
    .desc-p {
      font-size: 18px;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 30px;
      margin-top: 59px;
      margin-bottom: 34px;
    }
  }
  .data-img {
    width: 720px;
    height: 960px;
    padding-left: 100px;
    object-fit: cover;
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page7 {
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: $mobile-header-height;
    .text-div {
      padding-right: 0;
      text-align: center;
      .desc-p {
        font-size: 0.27rem;
        line-height: 0.44rem;
        margin: auto;
        margin-top: 0.36rem;
        margin-bottom: 0.27rem;
        width: 86%;
      }
    }
    .img-div {
      width: 86%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin: auto;
      height: 6.45rem;
      .data-img {
        width: 100%;
        height: unset;
        padding-left: 0;
        object-fit: cover;
      }
    }
  }
}
</style>
