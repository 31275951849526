<!--
 * @Author: your name
 * @Date: 2021-12-03 09:13:47
 * @LastEditTime: 2022-01-26 18:16:05
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page8.vue
-->
<template>
  <div class="fx101-page81 pt80">
    <ctitle titleb="控制系统"></ctitle>
    <div class="img-div">
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/stld/14.png"
        alt="上太科技"
        @load="imgLoad"
      />
      <div class="i-desc lt-d">
        <i class="iconfont iconguochong"></i>
        <p class="line-label">过充</p>
      </div>
      <div class="i-desc lb-d">
        <i class="iconfont iconguofang"></i>
        <p class="line-label">过放</p>
      </div>

      <div class="i-desc r-desc">
        <i class="iconfont iconfangshui"></i>
        <p class="line-label">防水</p>
      </div>

      <div class="i-desc r-desc b-desc">
        <i class="iconfont iconfanglei"></i>
        <p class="line-label">防雷</p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    imgLoad() {
      let div = document.querySelector(".fx101-page81");
      let img = div.querySelector(".data-img");
      let lp = div.querySelector(".lt-d");
      let lb = div.querySelector(".lb-d");
      let rp = div.querySelector(".r-desc");
      let rb = div.querySelector(".b-desc");
      rp.style.left = `calc(50% + ${img.width}px )`;
      rb.style.left = `calc(50% + ${img.width}px )`;
      lp.style.right = `calc(50% + ${img.width}px)`;
      lb.style.right = `calc(50% + ${img.width}px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fx101-page81 {
  position: relative;
  height: 100vh;
  display: flex;
  margin-top: 180px;
  flex-direction: column;
  align-items: center;
  .img-div {
    height: 80%;
    margin-top: 50px;
    position: relative;
    img {
      height: 100%;
    }
  }
}

.i-desc {
  position: absolute;
  top: 35%;
  width: 100px;
  overflow: hidden;
  color: $color-active;
  text-align: center;
  .iconfont {
    font-size: 32px;
  }

  &.r-desc {
    text-align: center;
  }
  &.b-desc {
    top: 64%;
  }
  &.lb-d {
    top: 64%;

    .line-label {
      font-weight: bold;
    }
  }
  &.lt-d {
    top: 35%;

    .line-label {
      font-weight: bold;
    }
  }

  p {
    margin: 0;
    white-space: nowrap;
    color: $color-active;
    position: relative;
    height: 0;
    // height: 16px;
    margin-top: 10px;
    overflow: hidden;
    font-size: 22px;
    font-weight: bold;
    &.c-g {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
    }
  }
}
@media screen and (max-height: 800px) {
  .fx101-page81 {
    .img-div {
      height: 68%;
    }
  }
}
@media screen and (min-height: 800px) {
  .fx101-page81 {
    .img-div {
      height: 78%;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page81 {
    margin-top: 1.64rem;
    height: auto;
    .img-div {
      height: unset;
      width: 100%;
      margin-top: 0.45rem;
      position: relative;
      display: flex;
      justify-content: center;
      img {
        height: unset;
        width: 40%;
      }
    }
  }

  .i-desc {
    width: auto;
    .iconfont {
      font-size: 0.44rem;
    }

    &.r-desc {
      text-align: center;
      left: 80% !important;
    }
    &.b-desc {
      top: 64%;
    }
    &.lb-d {
      top: 64%;
      right: 80% !important;

      .line-label {
        font-weight: bold;
      }
    }
    &.lt-d {
      top: 35%;
      right: 80% !important;

      .line-label {
        font-weight: bold;
      }
    }

    p {
      margin: 0;
      height: auto;
      // height: 16px;
      margin-top: 6px;
      font-size: 0.22rem;
      &.c-g {
        font-size: 0.27rem;
      }
    }
  }
}
</style>
