<!--
 * @Author: your name
 * @Date: 2021-12-03 07:49:45
 * @LastEditTime: 2021-12-15 13:50:42
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\fx101\page1.vue
-->
<template>
  <div class="fx101-page1">
    <div class="img-div"></div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page1 {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  .img-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      height: 100%;
      margin-top: 20vh;
      // width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      object-fit: contain;
      // object-fit: cover;
    }
  }
  .text-div {
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .model-span {
      font-size: 84px;
      font-weight: 500;
      color: #ffffff;
    }
    .label-span {
      font-size: 36px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 20px;
    }
  }
}
</style>
